import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import Button from '../../Components/Button/Button';
import picture from '../../images/YouthWrestling.jpeg';

import './styles.scss';

// markup
const YouthWrestlingPage = () => {
  return (
    <Layout className='Youth-Wrestling'>
      <Banner title='Youth Wrestling' />
      <section className='Background-White'>
        <div className='Frame-Inner Grid Grid-L Grid-1-1 Grid-Gap-4'>
          <div>
            <h2>Join The Team</h2>
            <p>
              Get invloved with wrestling here in Brookville sooner with our
              Youth Wrestling program where we teach the fundamentals, and lay
              the foundation of Brookville's next biggest wrestling stars.
            </p>
          </div>
          <div>
            <img
              src={picture}
              alt='Youth wrestling team gathered with the high school team'
            />
          </div>
        </div>
      </section>
      <section className='Background-Blue Contact-Section'>
        <div className='Smaller-Frame Grid Grid-L Grid-1 Grid-Gap-4 Grid-Center'>
          <div className='Text-Center'>
            <h2>Sign Up For Youth Wrestling</h2>
            <p>
              Find more resources regarding our Youth Wrestling program down
              below and see what great things are going on, on our Youth
              Wrestling Facebook page.
            </p>
          </div>
          <Button
            className='Button-White'
            buttonText='Sign Up For Youth Wrestling'
            onClick='https://tshq.bluesombrero.com/bvillewrstlng'
          />
          <Button
            className='Button-White'
            buttonText='Visit Our Facebook Page'
            onClick='https://www.facebook.com/BrookvilleYW'
          />
        </div>
      </section>
    </Layout>
  );
};

export default YouthWrestlingPage;
